<template>
  <div class="model-banner">
    <v-card
      v-if="modelToDisplay"
      hover
      class="mx-auto shape car-item"
      max-width="344"
      @click="leadButtonClicked"
    >
      <div class="car-border">
        <img :src="require('@/assets/border.svg')">
      </div>
      <p class="car-title">
        {{ modelToDisplay.name }}
      </p>
      <hr class="hr-line">
      <p class="car-text">
        <span v-if="modelToDisplay.pricePerDay > 0">
          {{ $t("ModelsOverviewPage.priceFrom") }}
          <span class="car-price-number">{{
            modelPriceToString()
          }}</span>
          {{ $t("ModelsOverviewPage.pricePerDay") }}
        </span>
        <span v-if="modelToDisplay.pricePerDay == 0">
          {{ $t("ModelsOverviewPage.noPrice") }}
        </span>
      </p>

      <div
        v-if="modelToDisplay.tag"
        class="newtag"
      >
        <div class="tag-body" />
        <div class="tag-text">
          {{ modelToDisplay.tag }}
        </div>
      </div>
      <p class="car-text-xs">
        inkl. Mwst
      </p>
      <v-avatar
        class="car-img"
        size="250"
        tile
      >
        <key-image :model-id="modelToDisplay.modelId" />
      </v-avatar>
    </v-card>

    <v-skeleton-loader
      v-else
      min-width="250"
      min-height="250"
      type="article"
    />
  </div>
</template>

<script>
import KeyImage from "./KeyImage";

export default {
  components: {
    KeyImage
  },

  props: {
    modelToDisplay: { type: Object },
    openLiveBookingTool: { type: Boolean, default: false },
  },
  methods: {
    modelPriceToString(){
      const price = this.$n(Math.ceil(this.modelToDisplay.pricePerDay), "currency")
      return price.replace(/[,\\.]00\s?/g,'');
    },

    leadButtonClicked() {
      if (this.openLiveBookingTool) {
        try {
          sessionStorage.modelId = this.modelToDisplay.modelId;
        } catch (error) {
          console.error(error);
        }
        window.open(this.$t("general.url.bookingTool"), "_parent");
      } else {
        this.$router.push({
          name: "vehicleSearch",
          query: { modell: this.modelToDisplay.modelId },
        });
      }
    },
  },
};
</script>

<style src="@/styles/pages/ModelBanner.scss" lang="scss"></style>