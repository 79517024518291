<template>
  <div>
    <ModelBanner :model-to-display="car" />
  </div>
</template>

<script>
import ModelBanner from "@/components/ui/ModelBanner.vue";

export default {
  name: "EmbedModelBanner",
  components: {
    ModelBanner,
  },
  props: {
    modelId: {
      type: String,
      default: null,
    },
  },
  computed: {
    car() {
      return this.$store.getters.models.find((x) => x.modelId === this.modelId);
    },
  },
  async beforeMount() {
    // TODO: Fetch Master Data if not already done
  },
}
</script>

<style scoped>

</style>