<template>
  <span>{{ format_date(date) }}</span>
</template>

<script>
export default {
  name: "DateDisplay",
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  methods: {
    format_date(date){
      if (date) {
        // Date information is received as "Europe/Berlin" and should always be displayed as if user is in this timezone.
        const dateObj = new Date(date)
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Berlin' }
        // Use vue internationalization framework to get the correct weekday and month names
        return dateObj.toLocaleDateString(this.$i18n.locale, options)
      } else {
        return ''
      }
    },
  },
}
</script>
