import Vue from "vue";
import i18n from "@/plugins/i18n";
import vuetify from "./plugins/vuetify";

import VCalendar from "v-calendar";

import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCar } from "@fortawesome/free-solid-svg-icons/faCar";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";

import store from "./store/store";
import VueAxios from "vue-axios";
import axios from "axios";

import SingleLine from "./components/standalone/SingleLine.vue";
import EmbedModelBanner from "./components/standalone/EmbedModelBanner.vue";

Vue.component("FontAwesomeIcon", FontAwesomeIcon );
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
library.add(
  faCar,
  faCalendarAlt,
  faMapMarkerAlt,
  faTimes,
  faExclamationTriangle,
  faInfoCircle
);

Vue.use(VCalendar, {
  componentPrefix: "vc",
  screens: {
    "sm": "640px",  // (min-width: 640px)
    "md": "720px",  // (min-width: 768px)
    "lg": "1024px", // (min-width: 1024px)
    "xl": "1280px"  // (min-width: 1280px)
  }
});

if (!window.apiEndpoint && document.currentScript) {
  window.apiEndpoint = new URL(document.currentScript.src).origin;
  console.log("Setting API to:", window.apiEndpoint);
}

Vue.use(VueAxios, axios);
if (window.apiEndpoint) {
  axios.defaults.baseURL= window.apiEndpoint + "/api/v1/";
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT + "/api/v1/";
}

if (document.getElementById("app-single-line")) {
  const element = document.getElementById("app-single-line");
  const props = {};
  if (element.hasAttribute(("data-model-id"))) {
    props.modelId = element.getAttribute("data-model-id");
  }
  if (element.hasAttribute(("data-site-id"))) {
    props.siteId = element.getAttribute("data-site-id");
  }
  if (element.hasAttribute(("data-model-selection"))) {
    props.disableModelSelection = element.getAttribute("data-model-selection") === "false";
  }
  if (element.hasAttribute(("data-site-selection"))) {
    props.disableSiteSelection = element.getAttribute("data-site-selection") === "false";
  }

  new Vue({
    i18n,
    store,
    vuetify,
    render: h => h(SingleLine, {props})
  }).$mount("#app-single-line");
}

const bannerElements = document.getElementsByClassName("app-model-banner")
for (let i = 0; i < bannerElements.length; i++) {

  // check if data-model-id attribute is set
  if (!bannerElements[i].hasAttribute("data-model-id")) {
    continue;
  }

  // create child div to mount vue app
  const childDiv = document.createElement("div");
  bannerElements[i].appendChild(childDiv);

  new Vue({
    i18n,
    store,
    vuetify,
    render: h => h(EmbedModelBanner, {props: {modelId: bannerElements[i].getAttribute("data-model-id")}})
  }).$mount(childDiv);
}